import Translator from "bazinga-translator"

declare global {
    interface Window {
        _translations: {
            messages: string
        }
    }
}


Translator.fromJSON(window._translations.messages)

document.querySelector(".translations")?.remove()
